.features__wrapper {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 40px;
	margin: 20px 0;
}

.features {
	// width: 600px;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
	padding: 10px 20px;
	box-sizing: border-box;
	border-radius: 4px;

	.features__header {
		// margin-bottom: 10px;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid rgba(0, 0, 0, 0.3);
		padding: 10px 0;
		margin-bottom: 10px;

		.features__header__actions {
			display: flex;
			justify-content: space-between;
			align-items: center;

			gap: 10px;

			button {
				padding: 0;
				height: auto;
				min-height: auto;
			}
		}
	}

	.features__content {
		max-height: 400px;
		overflow-y: auto;

		.features__content__item {
			&:not(:last-child) {
				margin-bottom: 5px;
			}
		}
	}

	.features__footer {
		border-top: 1px solid rgba(0, 0, 0, 0.3);
		padding: 10px 0;
		margin-top: 10px;

		display: flex;
		justify-content: space-between;

		.features__footer--align-left {
			display: flex;
		}

		.features__footer--align-right {
			display: flex;
		}
	}
}
