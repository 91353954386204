:root {
	--variable: ;
}

.asteria--state-hidden {
	visibility: hidden;
	pointer-events: all;
}

.asteria-component__invoice-document {

}

.asteria-component__invoice-document__form {
	display: grid;
	grid-template-columns: 1fr 500px;
  	height: 100%;
	overflow: hidden;
}

.asteria-component__invoice-document-controls {

}

.asteria-component__invoice-document__page-details {
	&.asteria--state-drawing {
		cursor: crosshair;

		.asteria-component__invoice-document__section {
			@apply pointer-events-none;
		}
	}
}

.asteria-component__invoice-document__page-wrapper {
	@apply relative;
}

.asteria-component__invoice-document__page {
}

.asteria-component__invoice-document__section {
	@apply border border-solid absolute;

	top: var(--y);
	left: var(--x);
	height: var(--height);
	width: var(--width);

	&.asteria--type-selected {
		border-color: rgba(238, 143, 139, 1);
		background-color: rgba(238, 143, 139, 0.1);
	}

	&.asteria--type-highlight {
		@apply cursor-pointer;

		border-color: rgb(146, 139, 238);
		background-color: rgba(146, 139, 238, 0.1);

		&:hover, &.asteria--state-highlight {
			background-color: rgba(146, 139, 238, 0.2);
		}

		&.asteria--state-highlight {
			background-color: rgba(146, 139, 238, 0.4);
		}
	}
}

.asteria-component__invoice-document__section__label-wrapper {
	@apply relative;
}

.asteria-component__invoice-document__section__connector-wrapper {
	@apply relative;
	margin: auto 0;
}

.asteria-component__invoice-document__section__label {
	@apply absolute -top-6 left-0 text-blue-400 text-sm;
}
